import React from "react";
import "../styles/Calendaire.css";

export default function Calendaire() {
     return (
          <div style="{{" padding:="" "0%="" 1%",="" }}="">
               <p style="{{" textTransform:="" "uppercase",="" fontSize:="" "17px",="" fontWeight:="" "500",="" letterSpacing:="" "11px",="" margin:="" "o%",="" }}="">
                    Kommende
               </p>

               <ul style="{{" margin:="" "0%",="" listStyle:="" "none",="" padding:="" }}="">
                    <li>
                         2025 - Neues Projekt für The Interstring Project (DE).
                         Neues Stück für 4 E-Gitarren.
                    </li>
                    <li>
                         12/04/2025 - Infused 3'600'000ms, neues Multimedia-Projekt
                         Projekt, in Auftrag gegeben von der Zagreg Music Bienale,
                         Uraufführung durch das Ensemble Elision im Rahmen der ZMB25 (CH).
                    </li>
                    <li>
                         tbc/01/2025 - Infused 3'600'000ms (reduzierte Version),
                         neues Multimediaprojekt, als Teil des ICST Pre-PhD Programms
                         an der ZHdK (CH), uraufgeführt von Joshua Hyde + Francisco
                         Uberto.
                    </li>
                    <li>
                         24/10/24 All my neurons have clits, Flock Lab,
                         Botschaft von Argentinien in Paris, Paris (FR)
                    </li>
                    <li>
                         17/10/24 Alle meine Neuronen haben Klitoris, Flock Lab, CRD de
                         Pantin, Pantin (FR)
                    </li>
                    <li>
                         15/10/24 LIFE electronics, Émilie Girard-Charest,
                         Weihnachtsmarkt, Phoenix in Valenciennes (FR)
                    </li>

                    {/* <li>
                         28/06/24 1Up - Soloauftritt, Gallerie du Haut Pavé, Paris
                         (FR)
                    </li> */}
                    {/* <li>
                         15/05/24 K.A.M.I.K.A.Z.E. B-L-Duo im Kater Blau in
                         Berlin im Rahmen von classicalNEXT '24(DE)
                    </li>
                    <li>
                         27/04/24 K.A.M.I.K.A.Z.E. B-L-Duo im Musiksalon
                         (Stadthalle) Singapur (SG)
                    </li> */}
                    {/* <li>
                         15/03/24 Paradise (or how I'll get there from a door  im
                         im dritten Stock), salle d'orchestre à la HEAR,
                         Straßburg (FR)
                    </li>
                    <li>
                         02/02/24 - LIFE electronics. Mastering im Studio "la
                         cave à son" (+ info bald), Paris (FR)
                    </li>
                    <li>
                         24.01.11 - Ich ganz allein - Soloauftritt -
                         Maison de l'Argentine - Paris (FR)
                    </li>

                    <li>
                         25/11/23 - K.A.M.I.K.A.Z.E - B-L duo - hcfm// festival
                         (UK)
                    </li>
                    <li>
                         11/11/23 - P.A.R.A.D.I.S.E. - Li Li-Chin - C-Lab -
                         Taipeh (TW)
                    </li>
                    <li>
                         26/10/23 - Elettrificare il Rosa - Trio Métallique -
                         Cité International des Arts, Paris (FR)
                    </li> */}
               </ul>
          </div>
     );
}
